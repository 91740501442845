













import { ExecuteLessonModel, LessonService } from "@/services/service";
import { Component, Vue } from "vue-property-decorator";
@Component({ components: {} })
export default class TeacherDesktopLesson extends Vue {
    private lesson: ExecuteLessonModel | null = null;

    async created() {
        const id = parseInt(this.$route.params.id);
        this.lesson = await LessonService.getLessonByIdForTeacher({ lessonId: id! });
    }

    getTitle() {
        return this.lesson?.NextLessonId ? "Завершить урок" : "Завершить курс";
    }

    async goToCourse() {       
        this.$router.push({
            name: "TeacherCourse",
            params: { id: this.lesson?.CourseId!.toString()! },
        });
    }
}
