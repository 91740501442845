


























































































import { setFilePropertyToEntity } from "@/helpers/fileHelper";
import { Field } from "@/interfaces/Table";
import { AnswerModel, QuestionModel } from "@/services/service";
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { QuestionTypeList } from "@/enums/courseEnums";
import { maxId, saveToArray } from "@/helpers/arrayHelper";
import AddEditAnswer from "@/views/Courses/Answers/AddEditAnswer.vue";
import { cloneDeep } from "lodash";
@Component({ components: { AddEditAnswer } })
export default class AddEditQuestion extends Vue {
  @Prop({ default: true }) model?: QuestionModel;
  private dialog = true;
  private fileName = "";
  private currentFile: File | null = null;
  private QuestionTypeList = QuestionTypeList;
  private addEditAnswer: AnswerModel = {};
  private showAddEditAnswer = false;
  private answersLength = this.model?.Answers?.length;

  private getTitle(): string {
    return "Добавить вопрос";
  }

  private save() {
    this.$emit("saved", this.model);
  }

  private cancel() {
    this.$emit("canceled");
  }

  @Watch("dialog")
  dialogChanged(val) {
    if (!val) {
      this.$emit("canceled");
    }
  }

  get getHeaders() {
    const headers: Array<Field> = [
      { text: "Название", value: "Text", sortable: false },
    ];
    return headers;
  }

  private async selectFile(file: File) {
    this.fileName = file?.name;
    await setFilePropertyToEntity(file, this.model);
    this.currentFile = file;
  }

  private addAnswer() {
    this.addEditAnswer = new AnswerModel();
    this.addEditAnswer.InnerId = maxId(this.model!.Answers!);
    this.showAddEditAnswer = true;
  }

  private showAnswer(val: AnswerModel) {
    this.addEditAnswer = cloneDeep(val);
    this.showAddEditAnswer = true;
  }
  private saveAnswer() {
    this.answersLength = this.model!.Answers!.length + 1;
    const newItems = saveToArray(this.model!.Answers!, this.addEditAnswer);
    this.$nextTick(() => {
      this.model!.Answers! = newItems;
      this.answersLength = this.model!.Answers!.length;
    })
    
    this.showAddEditAnswer = false;
  }
  private cancelShowAnswer() {
    this.showAddEditAnswer = false;
  }
}
