





















import { ExecuteLessonModel, LessonService } from "@/services/service";
import { Component, Vue } from "vue-property-decorator";
@Component({ components: {} })
export default class StudentLesson extends Vue {
  private lesson: ExecuteLessonModel | null = null;

  async created() {
    const id = parseInt(this.$route.params.id);
    this.lesson = await LessonService.getLessonById({ lessonId: id! });
    await LessonService.setLessonInProgress({ lessonId: id });
  }

  getTitle() {
    return this.lesson?.NextLessonId ? "Завершить урок" : "Завершить курс";
  }

  async nextLesson() {
    const id = parseInt(this.$route.params.id);
    await LessonService.setLessonDone({ lessonId: id });
    this.$router.push({
      name: "StudentDesktopCourse",
      params: { id: this.lesson?.CourseId!.toString()! },
    });
  }
}
