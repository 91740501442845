<template>
    <div class="home">
       Рабочий стол студента
    </div>
</template>

<script>
export default {
    name: "Home",
    
};
</script>
