import { first } from "lodash";
import Vue from "vue";

declare module "vue/types/vue" {
    export interface Vue {
        $validate: () => boolean;
        resetValidation: () => boolean;

    }
}

export default {
    install: function(VueInstance) {
        VueInstance.prototype.$validate = function(): boolean {
           
            const result = this.validate();
          
            if (this?._vnode?.tag !== 'form' || result)
            {
                return result;
            }
          
            const firstNoValidInputId = first(Object.entries(this?.errorBag)?.find((f) => f[1] == true));
        
            if (firstNoValidInputId)
            {
                const firstNoValidInput = this.inputs.find((f) => f._uid == firstNoValidInputId);

                if (firstNoValidInput.$el.scrollIntoView)
                {
                    firstNoValidInput.$el.scrollIntoView({block: "center", behavior: "smooth"});
                }
            }
            return false;
        };
    },
};
