













































































































































































import { UserService, AddEditUserModel, EmployeeDocumentModel, RoleRightModel, UserCustomRightModel, RightService, FileService } from "@/services/service";
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { deleteFromArray, maxId, saveToArray } from "@/helpers/arrayHelper";
import CDatePicker from "@/components/CDatePicker.vue";
import { SEXES } from "@/constants/common";
import { CreateUserRoleList } from "@/constants/auth";
import { Field } from "@/interfaces/Table";
import AddEditEmployeeDocument from "@/views/Users/AddEditEmployeeDocuments.vue";
import { DocumentTypeList } from "@/constants/common";
import { RightTitles } from "@/constants/rights";
import { required } from "@/helpers/rules";
import { downloadBase64File, downloadFile } from "@/helpers/fileHelper";

@Component({ components: { CDatePicker, AddEditEmployeeDocument } })
export default class AddEditUser extends Vue {
    @Prop({ required: true }) model?: AddEditUserModel;
    private dialog = true;
    private sexes = SEXES;
    private rolesList = CreateUserRoleList;
    private tabs = [
        { value: "user_property", title: "Свойства пользователя" },
        { value: "rights", title: "Права" },
    ];
    private currentTab = this.tabs[0].value;

    private toSelectRoleList = CreateUserRoleList.filter((q) => q.authtext != this.model?.Role);

    private addEditEmployeeDocument = {} as any;
    private showAddEditDocument = false;
    private selectedRole = "";
    private roleRights: Array<string> = [];
    @Prop({ required: true }) allRoleRights!: Array<RoleRightModel>;
    private valid = false;

    public startValidation = false;
    mounted() {
        if (this.startValidation) {
            (this.$refs.form as Vue).$validate();
        }
    }

    get commonRules() {
        return [required()];
    }
    get passwordRules() {
        if (this.isAdded) {
            return [required()];
        }
        return [];
    }

    get Title() {
        return this.isAdded ? "Добавить пользователя" : "Изменить пользователя";
    }
    async save() {
        (this.$refs.form as Vue).$validate();

        if (!this.valid) {
            this.startValidation = true;
            return;
        }
        const action = this.isAdded ? UserService.addUser : UserService.editUser;
        const result = await action({ body: this.model });
        this.$emit(this.isAdded ? "added" : "changed", result);
    }

    private addDocument() {
        this.addEditEmployeeDocument = {} as any;
        this.addEditEmployeeDocument.InnerId = maxId(this.model?.ΕmployeeDocuments!);
        this.showAddEditDocument = true;
    }

    private deleteDocument(item) {
        deleteFromArray(this.model?.ΕmployeeDocuments!, item);
    }

    get employeeDocuments () {
        return this.model?.ΕmployeeDocuments?.filter(q=> !q.IsDeleted);
    }

    cancelShowDocument() {
        this.showAddEditDocument = false;
    }

    private saveDocument(val: EmployeeDocumentModel) {
        saveToArray(this.model?.ΕmployeeDocuments!, val);
        this.showAddEditDocument = false;
    }

    cancel() {
        this.$emit("canceled");
    }

    get isAdded() {
        return this.model!.Id == null;
    }

    @Watch("dialog")
    onDialog(val) {
        if (!val) {
            this.cancel();
        }
    }

    get getHeaders() {
        const headers: Array<Field> = [
            { text: "Имя файла", value: "FileName", sortable: false },
            { text: "Тип", value: "DocumentType", sortable: false },
            { text: "", value: "action" },
        ];
        return headers;
    }

    getDocumentTypeTitle(item) {
        const documentType = DocumentTypeList.find((i) => i.value == item.DocumentType);
        return documentType?.text;
    }

    downloadDocument(item: EmployeeDocumentModel) {
        if (item.FileContent) {
            downloadBase64File(item.FileContent, item.FileName);
        } else if (item.UniqueNumber) {
            downloadFile(item);
        }
        ///
    }

    getRoleTitle(item) {
        return RightTitles[item].title;
    }

    get selectedRoleRights() {
        if (!this.selectedRole) return [];
        return this.allRoleRights.filter((q) => q.Role == this.selectedRole);
    }

    getTitleRole(item) {
        const value = RightTitles[item.Right].title;
        return value;
    }
    moveToUser() {
        const selectedRigths = this.selectedRoleRights.filter((q) => q.Checked === true);
        if (selectedRigths.length == 0) {
            alert("Выберите права");
            return;
        }
        const existsCustomRights = this.model?.UserCustomRights?.filter((q) => selectedRigths.filter((i) => i.Right == q.Right).length > 0);
        if (existsCustomRights!.length > 0) {
            alert("Выбранные права уже есть у пользователя");
            return;
        }

        const existRoleRights = this.roleRights.filter((q) => selectedRigths.filter((i) => i.Right == q).length > 0);

        if (existRoleRights!.length > 0) {
            alert("Выбранные права уже есть у роли пользователя");
            return;
        }

        for (let i = 0; i < selectedRigths.length; i++) {
            const rightModel = new UserCustomRightModel();
            rightModel.Right = selectedRigths[i].Right;
            this.model?.UserCustomRights!.push(rightModel);
        }
    }
    moveFromUser() {
        const selectedRigths = this.model?.UserCustomRights!.filter((q) => q.Checked === true);
        if (selectedRigths!.length == 0) {
            alert("Выберите права");
            return;
        }
        for (let i = 0; i < selectedRigths!.length; i++) {
            const ind = this.model?.UserCustomRights?.indexOf(selectedRigths![i]);
            this.model?.UserCustomRights!.splice(ind!, 1);
        }
    }
    async created() {
        if (this.model?.Role) {
            this.roleRights = await RightService.getRoleRights({
                role: this.model?.Role,
            });
        } else {
            this.roleRights = [];
        }
    }
}
