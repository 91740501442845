















































import { Component, Vue, Watch } from "vue-property-decorator";
import { CreateCourseCommand } from "@/services/service";
import { FileModel } from "@/models/file-models/FileModel";
import { setFilePropertyToEntity } from "@/helpers/fileHelper";
@Component
export default class AddCourse extends Vue {
  private dialog = true;
  private model: CreateCourseCommand = {};
  private fileModel = {} as FileModel;
  private fileName = "";
  private currentFile: File | null = null;

  @Watch("dialog")
  onDialogChanged(val: boolean) {
    if (!val) {
      this.$emit("cancel");
    }
  }

  private save() {
    this.$emit("save", this.model);
  }
  private cancel() {
    this.$emit("cancel");
  }

  private async selectFile(file: File) {
    this.fileName = file?.name;
    await setFilePropertyToEntity(file, this.model);
    this.currentFile = file;
  }
}
