















import { CourseItemModelWithStat, ScheduleService } from "@/services/service";
import { Component, Vue } from "vue-property-decorator";

@Component({ components: {} })
export default class StudentDesktop extends Vue {
  private courses: CourseItemModelWithStat[] = [];

  async created() {
    this.courses = await ScheduleService.getStudentCourses();
  }

  goToCourse(id) {
      this.$router.push({name: "StudentDesktopCourse",  params: { id: id }})
  }
}
