


















import { Component, Vue } from "vue-property-decorator";
import { LessonModel, LessonService } from "@/services/service";

@Component({ components: {} })
export default class TeacherCourse extends Vue {
    private lessons: LessonModel[] = [];
    async created() {
        const id = this.$route.params.id;
        this.lessons = await LessonService.getCourseLessonsForTeacher({
            courseId: parseInt(id),
        });
    }
    goToLesson(lesson) {
        this.$router.push({
            name: "TeacherDesktopLesson",
            params: { id: lesson.Id },
        });
    }
    goToDesktop() {
        this.$router.push({
            name: "TeacherDesktop",
        });
    }
}
