export const ViewUsers = "ViewUsers";

export const AddUser = "AddUser";

export const AddStudent = "AddStudent";

export const EditUser = "EditUser";

export const EditStudent = "EditStudent";

export const ViewSubdivision = "ViewSubdivision";

export const AddSubdivision = "AddSubdivision";

export const EditSubdivision = "EditSubdivision";

export const ViewSchedule = "ViewSchedule";

export const AddSchedule = "AddSchedule";

export const EditSchedule = "EditSchedule";

export const ViewCourse = "ViewCourse";

export const AddCourse = "AddCourse";

export const EditCourse = "EditCourse";

export const RightTitles = {
  ViewUsers: { value: ViewUsers, title: "Просматривать пользователей" },
  AddUser: { value: AddUser, title: "Добавлять пользователей" },
  EditUser: { value: EditUser, title: "Редактировать пользователей" },
  AddStudent: { value: AddUser, title: "Добавлять студентов" },
  EditStudent: { value: EditUser, title: "Редактировать студентов" },
  ViewSubdivision: {
    value: ViewSubdivision,
    title: "Просматривать подразделения",
  },
  AddSubdivision: { value: AddSubdivision, title: "Добавлять подразделения" },
  EditSubdivision: {
    value: EditSubdivision,
    title: "Редактировать подразделение",
  },
  ViewSchedule: { value: ViewSchedule, title: "Просматривать расписание" },
  AddSchedule: { value: AddSchedule, title: "Добавлять занятие" },
  EditSchedule: { value: EditSchedule, title: "Редактировать занятие" },
  ViewCourse: { value: ViewCourse, title: "Просматировать курсы" },
  AddCourse: { value: AddCourse, title: "Добавлять курс" },
  EditCourse: { value: EditCourse, title: "Редактировать курс" },
};
