


























































































import { DayOfWeekList, LessonDuration } from "@/constants/common";
import {
  CourseItemModel,
  ScheduleModel,
  ScheduleService,
  ScheduleStudentModel,
  SubdivisionModel,
  UserItemModel,
} from "@/services/service";
import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import CTimePicker from "@/components/CTimePicker.vue";
import StudentList from "@/views/Management/Schedule/StudentList.vue";

@Component({ components: { CTimePicker, StudentList } })
export default class AddSchedule extends Vue {
  @Prop({ required: true }) teachers!: UserItemModel[];
  @Prop({ required: true }) students!: UserItemModel[];
  private dialog = true;
  @Prop({ required: true }) model?: ScheduleModel;
  @Prop({ required: true }) subdivisions?: SubdivisionModel[];
  @Prop({ required: true }) courses?: CourseItemModel[];
  private DayOfWeekList = DayOfWeekList;
  private LessonDuration = LessonDuration;

  get Title() {
    return !this.model!.Id ? "Добавить занятие" : "Изменить занятие";
  }

  @Watch("dialog")
  onDialogChanged(val: boolean) {
    if (!val) {
      this.$emit("cancel");
    }
  }

  getLessonDuration(item: string) {
    return item?.substring(0, 5);
  }

  studentDeleted(item) {  
    this.model!.Students = this.model?.Students?.filter((q) => q.Id != item.Id);
  }

  studentAdded(items: Array<UserItemModel>) {
    const ar = [] as Array<ScheduleStudentModel>;
    items.forEach((q) => {
      ar.push({
        StudentId: q.Id,
        FirstName: q.FirstName,
        LastName: q.LastName,
        MiddleName: q.MiddleName,
        StageOfEducation: q.StageOfEducation,
        CourseId: null!,
      } as ScheduleStudentModel);
    });
    this.model!.Students!.push(...ar);
  }

  async save() {
    const action = this.isAdded
      ? ScheduleService.createSchedule
      : ScheduleService.updateSchedule;

    const result = await action({ body: this.model });
    this.$emit(this.isAdded ? "added" : "changed", result);
  }
  private cancel() {
    this.$emit("cancel");
  }
  get isAdded() {
    return this.model!.Id == null;
  }

  timeMask(value) {
    const hours = [/[0-2]/, value.charAt(0) === "2" ? /[0-3]/ : /[0-9]/];
    const minutes = [/[0-5]/, /[0-9]/];
    return value.length > 2 ? [...hours, ":", ...minutes] : hours;
  }
}
