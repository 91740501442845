































import { Component, Vue, Watch, Prop, Ref } from "vue-property-decorator";
import ListOfStudents from "@/views/Management/Schedule/ListOfStudents.vue";
import { UserItemModel } from "@/services/service";
@Component({ components: { ListOfStudents } })
export default class AddStudentToSchedule extends Vue {
  @Prop({ required: true }) students!: UserItemModel[];
  @Ref("tableOfStudents") tableOfStudents!: ListOfStudents;
  private selected = [] as Array<any>;
  private dialog = true;

  setSelected(val) {
    this.selected.splice(0, this.selected.length, ...val);
  }
  save() {
    const selected = this.tableOfStudents.getSelectedStudents();
    this.$emit("saved", selected);
  }
  cancel() {
    this.$emit("canceled");
  }

  @Watch("dialog")
  onDialogChanged(val: boolean) {
    if (!val) {
      this.$emit("canceled");
    }
  }
}
