export const saveToArray = (items: any[], item: any): any[] => {
    if (item.Id != null) {
        const findItem = items.find((l) => l.Id === item.Id);
        const index = items.indexOf(findItem!);
        items.splice(index!, 1, item);
    } else {
        const findItemByInner = items.find((l) => l.InnerId == item.InnerId);
        if (findItemByInner != null) {
            const indexByInner = items.indexOf(findItemByInner!);
            items.splice(indexByInner!, 1, item);
        } else {
            items.push(item);
        }
    }
    return items;
};

export const maxId = (items: any[]): number => {
    if (items.length == 0) {
        return 1;
    }

    const max = items.reduce(function(prev, current) {
        return prev.InnerId > current.InnerId ? prev : current;
    });

    return max.InnerId + 1;
};

export const deleteFromArray = (items: any[], item: any) => {
    if (!item.Id) {
        const index = items.indexOf(item);
        items.splice(index!, 1);
    } else {
        item.IsDeleted = true;
    }
};
