//import { FileService } from "@/services/service";

import { FileService } from "@/services/service";

export const toBase64 = (file: any) => {
    return new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result!.toString());
        reader.onerror = (error) => reject(error);
    });
};

export const toArrayBuffer = (file: any) => {
    return new Promise<ArrayBuffer>((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsArrayBuffer(file);
        reader.onload = () => resolve(reader.result as ArrayBuffer);
        reader.onerror = (error) => reject(error);
    });
};
export const setFilePropertyToEntity = async (file: any, entity: any) => {
    if (file != null) {
        entity.FileContent = await toBase64(file);
        entity.FileName = file.name;
    }
};

/**
 * Функция для скачивания файла по UniqueNumber.
 * @param {Object} sourcefile - объект, содержащий уникальный идентификатор и имя файла.
 * @param {string} sourcefile.UniqueNumber - уникальный идентификатор файла.
 * @param {string} sourcefile.FileName - имя файла.
 */

export const downloadBlob = (blob: any, fileName: string) => {
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        // IE:
        window.navigator.msSaveOrOpenBlob(blob, fileName);
    } else {
        //Chrome & Firefox
        const a = document.createElement("a");
        const url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
    }
};

export const downloadFile = async (sourcefile: any) => {
    try {
        const response = await FileService.getById(
            {
                id: sourcefile.UniqueNumber,
            },
            { responseType: "blob" }
        );
        const blob = new Blob([response]);
        downloadBlob(blob, sourcefile.FileName);
    } catch (error) {
        const errorMessage = `Произошла ошибка при попытке скачать файл. ${error.message}`;
        console.log(errorMessage);
        throw new Error(errorMessage);
    }
};

export function addBase64Header(data) {
    return "data:text/plain;base64," + data;
}

// Правила для <v-file-input :rules = [...]>
export const typeFileRule = (ckeckedFile: File | any, arrayLimitTypeFile: Array<string>): string | boolean => {
    for (let i = 0; i < arrayLimitTypeFile.length; i++) {
        try {
            if (ckeckedFile!.name.search(arrayLimitTypeFile[i]) != -1) {
                return true;
            }
        } catch {
            return true;
        }
    }
    return false;
};

export function downloadBase64File(contentBase64, fileName) {   
    const downloadLink = document.createElement("a");
    document.body.appendChild(downloadLink);
    downloadLink.href = contentBase64;
    downloadLink.target = "_self";
    downloadLink.download = fileName;
    downloadLink.click();
}
