import Axios from "axios";
 import { TOKEN_KEY, IS_LOCAL_KEY, UNAUTHORIZED_HTTP_CODE, PERMANENTREDIRECT_HTTP_CODE } from "@/constants/common";

console.log(process.env.VUE_APP_API_BASE_URL)
const axiosInstance = Axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL,
    withCredentials: false,
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
    }
});

if (localStorage.getItem(TOKEN_KEY)) {
    // Alter defaults after instance has been created
    axiosInstance.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem(TOKEN_KEY);
}

axiosInstance.interceptors.response.use(
    function(response) {
        return response;
    },
    function(error) {
        const isLocal = localStorage.getItem(IS_LOCAL_KEY) == "true";
        if (
            (!isLocal && !error.response) ||
            (error.response && (error.response.status === UNAUTHORIZED_HTTP_CODE || error.response.status === PERMANENTREDIRECT_HTTP_CODE))
        ) {
            axiosInstance.defaults.headers.common["Authorization"] = "";
            localStorage.removeItem(TOKEN_KEY);
            localStorage.removeItem(IS_LOCAL_KEY);
            if (window.location.pathname !== "/login") {
                let additionalParams = "";
                if (error.response != null && error.response.status === PERMANENTREDIRECT_HTTP_CODE) {
                    additionalParams = "?redirect=1";
                }
                window.location.href = "/login" + additionalParams;
            }
            console.log("axios promise error", error);
        } else {
            console.log("axios promise error", error);
            return Promise.reject(error);
        }
    }
);

export default axiosInstance;