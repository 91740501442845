





































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { isString, isArray, isNull, isUndefined } from "lodash";
import moment from "moment";
import { isEqual } from "lodash";


@Component
export default class CDatePicker extends Vue implements Vue  {
    @Prop() readonly rules!: Array<any>;
    @Prop() readonly value!: string;
    public model = this.initial(this.value) as any;
    @Watch("model", { immediate: true, deep: true })
    protected onContentChanged(val: string): void {       
        this.$emit("input", val);
    }
    @Watch("value", { immediate: true, deep: true })
    protected onContentChangedValue(newValue: string, oldValue: string): void {      
        this.model = this.initial(newValue);
        this.content = this.value;        
        this.viewContent = this.value ? this.formatDate(this.value, true) : null;
    }    

    protected initial(val: string): any {
        if (isNull(val) || isUndefined(val)) {
            return null;
        }

        if (isString(val)) {
            return (val ?? "") as string;
        }

        if (isArray(val)) {
            return val ?? [];
        }

        return val ?? ({} as string);
    }
    @Prop({ default: "" }) label!: string;
    @Prop({ default: false }) disabled!: boolean;
    @Prop({ default: "mdi-calendar" }) prependIcon!: string;   
    @Prop({ default: "" }) readonly id! : string;  

    public DDMMYYYYformat = "DD.MM.YYYY";
    public YYYYMMDDformat = "YYYY-MM-DD";
    public menu = false;     
    public onEnterPress(event)
    {
        this.$emit("onEnterPress", event);
    }
    private content: string | null ="";
    private viewContent : string | null = "";

    public handleInput() {
        (this.$refs.menu as any).save(this.content);
        this.viewContent = this.formatDate(this.content);
        this.model = this.content;
    }

    public handleClear() {
        this.content = null;
        this.model = this.content;
    } 

    public formatDate(val: any, isInitialValue = false) {
        const date = isInitialValue ? moment(val) : moment(val, this.YYYYMMDDformat);
        if (!date.isValid()) {
            return null;
        }

        return date.format(this.DDMMYYYYformat);
    }

    public parseDate() {
        const date = moment(this.viewContent, this.DDMMYYYYformat);
        if (date.isValid()) {
            this.content = date.format(this.YYYYMMDDformat);
            (this.$refs.menu as any).save(this.content);
            this.model = this.content;
        }

        this.viewContent = this.formatDate(this.content);
    }

    created() {
        this.model = this.value;
        this.content = this.value ? moment(this.value).format(this.YYYYMMDDformat) : null;
        this.viewContent = this.value ? this.formatDate(this.value, true) : null;
    }    
}
