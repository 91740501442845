































































// Validations
import { Component, Vue } from "vue-property-decorator";
import { AUTH_LOGOUT, AUTH_REQUEST } from "@/constants/auth";

import { required } from "@/helpers/rules";

@Component({
  components: {},
})
export default class Login extends Vue {
  private error = "";

  public showPwd = false;

  public userName = "";

  public password = "";

  public valid = true;

  public dialog = false;

  public certificates = [] as Array<any>;

  public rules = {
    UserName: [required("Введите имя пользователя")],
    Password: [required("Введите пароль")],
  };

  public async submit(): Promise<void> {
    try {
      await this.$store.dispatch(AUTH_REQUEST, {
        userName: this.userName,
        password: this.password,
      });
      this.redirectAfterLogin();
    } catch (error) {
      const e = error as any;
      this.error = e.message;
    }
    this.resetFrom();
  }

  public resetFrom(): void {
    (this.$refs.form as Vue & { reset: () => boolean }).reset();
    this.userName = "";
    this.password = "";
  }

  async created() {
    if (this.$store?.getters?.isLogout) {
      this.$store.commit(AUTH_LOGOUT);
    }
  }

  private redirectAfterLogin(): void {
    if (
      this.$store.getters.isAdministrator ||
      this.$store.getters.isSupervisor ||
      this.$store.getters.isReceptionist ||
      this.$store.getters.isBranchManager
    ) {
      this.$router.push({ name: "UserList" });
    }
    if (this.$store.getters.isStudent) {
      this.$router.push({ name: "StudentDesktop" });
    }
    if (this.$store.getters.isTeacher) {
      this.$router.push({ name: "TeacherDesktop" });
    }
    if (
      this.$store.getters.isMethodist ||
      this.$store.getters.isChiefMethodist ||
      this.$store.getters.isController
    ) {
      this.$router.push({ name: "CourseList" });
    }
  }
}
