



































































import { AddEditStudentModel, UserService } from "@/services/service";
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import CDatePicker from "@/components/CDatePicker.vue";
import { SEXES, StagesOfEducation } from "@/constants/common";
import { required } from "@/helpers/rules";
@Component({ components: { CDatePicker } })
export default class AddStudent extends Vue {
    @Prop({ required: true }) model?: AddEditStudentModel;
    private dialog = true;
    private sexes = SEXES;
    private StagesOfEducation = StagesOfEducation;
    private valid = false;

    public startValidation = false;
    mounted() {
        if (this.startValidation) {
            (this.$refs.form as Vue).$validate();
        }
    }

    get commonRules() {
        return [required()];
    }

    get Title() {
        return this.isAdded ? "Добавить студента" : "Изменить студента";
    }

    async save() {
        (this.$refs.form as Vue).$validate();

        if (!this.valid) {
            this.startValidation = true;
            return;
        }

        const action = this.isAdded ? UserService.addStudent : UserService.editStudent;
        const result = await action({ body: this.model });
        this.$emit(this.isAdded ? "added" : "changed", result);
    }

    cancel() {
        this.$emit("canceled");
    }

    get isAdded() {
        return this.model!.Id == null;
    }

    @Watch("model.FirstName")
    onChangeFirstName() {
        this.setUserName();
    }

    @Watch("model.LastName")
    onChangeLastName() {
        this.setUserName();
    }

    @Watch("dialog")
    onDialog(val) {
        if (!val) {
            this.cancel();
        }
    }

    setUserName() {
        if (this.isAdded) {
            this.model!.UserName = this.model?.LastName + " " + (this.model?.FirstName ?? "");
        }
    }
}
