







































import { Field } from "@/interfaces/Table";
import {
  UserItemModel,
  ScheduleStudentModel,
  CourseItemModel,
} from "@/services/service";
import { Component, Vue, Prop } from "vue-property-decorator";
import ListOfStudents from "@/views/Management/Schedule/ListOfStudents.vue";
import AddStudentToSchedule from "@/views/Management/Schedule/AddStudentToSchedule.vue";
import { formatDate } from "@/helpers/index";
@Component({ components: { ListOfStudents, AddStudentToSchedule } })
export default class StudentList extends Vue {
  @Prop({ required: true }) studentsData!: ScheduleStudentModel[];
  @Prop({ required: true }) students!: UserItemModel[];
  @Prop({ required: true }) courses!: CourseItemModel[];
  private selected = [] as Array<any>;
  private showAddScheduleDialog = false;
  private formatDate = formatDate;

  created() {
    ///    
  }

  // private fillSelectedStudents() {
  //   this.selectedStudents = this.students.filter((q) => this.studentsIds.indexOf(q.Id!) > -1);
  // }

  // @Watch("studentsIds", { immediate: true, deep: true })
  // studentsIdsChanged() {
  //   this.fillSelectedStudents();
  // }
  addStudentToSchedule() {
    this.showAddScheduleDialog = true;
  }

  studentSaved(val) {

    this.showAddScheduleDialog = false;
    this.$emit("studentAdded", val);
  }
  studentCanceled() {
    this.showAddScheduleDialog = false;
  }
  studentDeleted(item) {   
    this.$emit("studentDeleted", item);
  }

  get getHeaders() {
    const headers: Array<Field> = [
      { text: "Фамилия", value: "LastName", sortable: false },
      { text: "Имя", value: "FirstName", sortable: false },
      { text: "Отчество", value: "MiddleName", sortable: false },      
      { text: "Ступень обучения", value: "StageOfEducation", sortable: false },
      { text: "Курс", value: "Course", sortable: false },
      { text: "", value: "action", sortable: false },
    ];

    return headers;
  }
}
