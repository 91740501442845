
























import { Component, Vue } from "vue-property-decorator";
import { Field } from "@/interfaces/Table";
import { CourseItemModel, CourseService } from "@/services/service";
import AddCourse from "@/views/Courses/AddCourse.vue";
@Component({ components: { AddCourse } })
export default class CourseList extends Vue {
  private items: CourseItemModel[] = [];
  private dialog = false;
  private addCourse() {
    this.dialog = true;
  }

  private async saveCourse(model) {
    await CourseService.createCourse({ body: model });
    this.dialog = false;
    await this.getData();
  }

  public async editCourse(item: CourseItemModel) {
    this.$router.push({
      name: "EditCourse",
      params: { id: item.Id!.toString() },
    });
  }

  private cancelCourse() {
    this.dialog = false;
  }

  get getHeaders() {
    const headers: Array<Field> = [
      { text: "", value: "Image", sortable: false, width: "50" },
      { text: "Название", value: "Title", sortable: false },
    ];
    return headers;
  }

  async created() {    
    await this.getData();
  }

  private async getData() {
    this.items = await CourseService.getCourses();
  }
}
