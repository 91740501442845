































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { isString, isArray, isNull, isUndefined } from "lodash";
import moment from "moment";
import { isEqual } from "lodash";


@Component
export default class CTimePicker extends Vue implements Vue  {
    private menu2 = false;
      @Prop() readonly value!: string;
    public model = this.initial(this.value) as any;
    @Watch("model", { immediate: true, deep: true })
    protected onContentChanged(val: string): void {       
        this.$emit("input", val);
    }
    @Watch("value", { immediate: true, deep: true })
    protected onContentChangedValue(newValue: string, oldValue: string): void {      
        this.model = this.initial(newValue);       
    }    

    protected initial(val: string): any {
        if (isNull(val) || isUndefined(val)) {
            return null;
        }

        if (isString(val)) {
            return (val ?? "") as string;
        }

        if (isArray(val)) {
            return val ?? [];
        }

        return val ?? ({} as string);
    }
    @Prop({ default: "" }) label!: string;
    @Prop({ default: false }) disabled!: boolean;
}
