export const TOKEN_KEY = "accessToken";
export const IS_LOCAL_KEY = "IsLocal";
export const FIO_KEY = "fio";
export const PHOTO_KEY = "photo";

export const UNAUTHORIZED_HTTP_CODE = 401;
export const PERMANENTREDIRECT_HTTP_CODE = 308;
export const DDMMYYYYformat = "DD.MM.YYYY";
export const YYYYMMDDformat = "YYYY-MM-DD";
export const DDMMYYYYHHmmformat = "DD.MM.YYYY HH:mm";
export const YYYYMMDDTHHmmformat = "YYYY-MM-DDTHH:mm";
export const YYYYMMDDTHHmmssformat = "YYYY-MM-DDThh:mm:ss";

export const SEXES = [
  { value: true, text: "Мужской" },
  { value: false, text: "Женский" },
];

export const DocumentType = {
  FirstPassportPage: { value: 1, text: "Первая страница паспорта" },
  SecondPassportPage: { value: 2, text: "Вторая страница паспорта" },
  InsuranceNumber: { value: 3, text: "СНИЛС" },
  NoCriminalRecord: { value: 4, text: "Справка об отсутствии судимости" },
  Diploma: { value: 5, text: "Диплом" },
  IndividualTaxNumber: { value: 6, text: "ИНН" },
  EmployeementHistory: { value: 7, text: "Трудовая" },
  BankDetails: { value: 8, text: "Реквизиты банка" },
  OtherDocument: { value: 9, text: "Другой документ" },
};

export const DocumentTypeList = [
  DocumentType.FirstPassportPage,
  DocumentType.SecondPassportPage,
  DocumentType.InsuranceNumber,
  DocumentType.NoCriminalRecord,
  DocumentType.Diploma,
  DocumentType.IndividualTaxNumber,
  DocumentType.EmployeementHistory,
  DocumentType.BankDetails,
  DocumentType.OtherDocument,
];

export const DayOFWeekEnum = {
  Monday: { value: 1, text: "Понедельник" },
  Tuesday: { value: 2, text: "Вторник" },
  Wednesday: { value: 3, text: "Среда" },
  Thursday: { value: 4, text: "Четверг" },
  Friday: { value: 5, text: "Пятница" },
  Saturday: { value: 6, text: "Суббота" },
  Sunday: { value: 7, text: "Воскресенье" },
};

export const DayOfWeekList = [
  DayOFWeekEnum.Monday,
  DayOFWeekEnum.Tuesday,
  DayOFWeekEnum.Wednesday,
  DayOFWeekEnum.Thursday,
  DayOFWeekEnum.Friday,
  DayOFWeekEnum.Saturday,
  DayOFWeekEnum.Sunday,
];

export const LessonDuration = ["00:30:00", "01:00:00", "01:30:00", "02:00:00"];

export const StagesOfEducation = [1, 2, 3, 4, 5]