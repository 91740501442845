/** Generate by swagger-axios-codegen */
// tslint:disable
/* eslint-disable */
import axiosStatic, { AxiosInstance } from 'axios';

export interface IRequestOptions {
  headers?: any;
  baseURL?: string;
  responseType?: string;
}

export interface IRequestConfig {
  method?: any;
  headers?: any;
  url?: any;
  data?: any;
  params?: any;
}

// Add options interface
export interface ServiceOptions {
  axios?: AxiosInstance;
}

// Add default options
export const serviceOptions: ServiceOptions = {};

// Instance selector
export function axios(configs: IRequestConfig, resolve: (p: any) => void, reject: (p: any) => void): Promise<any> {
  if (serviceOptions.axios) {
    return serviceOptions.axios
      .request(configs)
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err);
      });
  } else {
    throw new Error('please inject yourself instance like axios  ');
  }
}

export function getConfigs(method: string, contentType: string, url: string, options: any): IRequestConfig {
  const configs: IRequestConfig = { ...options, method, url };
  configs.headers = {
    ...options.headers,
    'Content-Type': contentType
  };
  return configs;
}

const basePath = '';

export interface IList<T> extends Array<T> {}
export interface List<T> extends Array<T> {}
export interface IDictionary<TValue> {
  [key: string]: TValue;
}
export interface Dictionary<TValue> extends IDictionary<TValue> {}

export interface IListResult<T> {
  items?: T[];
}

export class ListResultDto<T> implements IListResult<T> {
  items?: T[];
}

export interface IPagedResult<T> extends IListResult<T> {
  totalCount?: number;
  items?: T[];
}

export class PagedResultDto<T> implements IPagedResult<T> {
  totalCount?: number;
  items?: T[];
}

// customer definition
// empty

export class AccountService {
  /**
   *
   */
  static login(
    params: {
      /** requestBody */
      body?: LoginCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<LoginResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Account/Login';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class CourseService {
  /**
   *
   */
  static getCourses(options: IRequestOptions = {}): Promise<CourseItemModel[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Course/GetCourses/GetCourses';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static createCourse(
    params: {
      /** requestBody */
      body?: CreateCourseCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CourseModel> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Course/InsertCourse/CreateCourse';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getById(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EditCourseModel> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Course/GetById/GetById';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updateCourse(
    params: {
      /** requestBody */
      body?: EditCourseCommand;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Course/UpdateCourse/UpdateCourse';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class FileService {
  /**
   *
   */
  static getById(
    params: {
      /**  */
      id?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/File/GetById/GetById';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class LessonService {
  /**
   *
   */
  static getCourseLessons(
    params: {
      /**  */
      courseId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<LessonItemModel[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Lesson/GetSchedules/GetCourseLessons';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { courseId: params['courseId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getCourseLessonsForTeacher(
    params: {
      /**  */
      courseId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<LessonModel[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Lesson/GetCourseLessonsForTeacher/GetCourseLessonsForTeacher';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { courseId: params['courseId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getLessonByIdForTeacher(
    params: {
      /**  */
      lessonId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ExecuteLessonModel> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Lesson/GetLessonByIdForTeacher/GetLessonByIdForTeacher';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { lessonId: params['lessonId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static setLessonInProgress(
    params: {
      /**  */
      lessonId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Lesson/SetLessonInProgress/SetLessonInProgress';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);
      configs.params = { lessonId: params['lessonId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static setLessonDone(
    params: {
      /**  */
      lessonId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Lesson/SetLessonDone/SetLessonDone';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);
      configs.params = { lessonId: params['lessonId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getLessonById(
    params: {
      /**  */
      lessonId?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ExecuteLessonModel> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Lesson/GetLessonById/GetLessonById';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { lessonId: params['lessonId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class RightService {
  /**
   *
   */
  static getRoleRights(
    params: {
      /**  */
      role?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Right/GetRoleRights/GetRoleRights';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { role: params['role'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAllRoleRights(options: IRequestOptions = {}): Promise<RoleRightModel[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Right/GetAllRoleRights/GetAllRoleRights';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class ScheduleService {
  /**
   *
   */
  static getSchedules(options: IRequestOptions = {}): Promise<ScheduleModel[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Schedule/GetSchedules/GetSchedules';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static createSchedule(
    params: {
      /** requestBody */
      body?: ScheduleModel;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ScheduleModel> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Schedule/InsertSchedule/CreateSchedule';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getById(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ScheduleModel> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Schedule/GetById/GetById';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updateSchedule(
    params: {
      /** requestBody */
      body?: ScheduleModel;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ScheduleModel> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Schedule/UpdateSchedule/UpdateSchedule';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getTeachers(options: IRequestOptions = {}): Promise<UserItemModel[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Schedule/GetTeachers/GetTeachers';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getData(options: IRequestOptions = {}): Promise<ScheduleData> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Schedule/GetData/GetData';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getStudentCourses(options: IRequestOptions = {}): Promise<CourseItemModelWithStat[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Schedule/GetStudentCourses/GetStudentCourses';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class SubdivisionService {
  /**
   *
   */
  static getSubdivisions(options: IRequestOptions = {}): Promise<SubdivisionModel[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Subdivision/GetSubdivisions/GetSubdivisions';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static createSubdivision(
    params: {
      /** requestBody */
      body?: SubdivisionModel;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SubdivisionModel> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Subdivision/InsertSubdivision/CreateSubdivision';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getById(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SubdivisionModel> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Subdivision/GetById/GetById';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updateSubdivision(
    params: {
      /** requestBody */
      body?: SubdivisionModel;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SubdivisionModel> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Subdivision/UpdateSubdivision/UpdateSubdivision';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getBranchManagers(options: IRequestOptions = {}): Promise<UserItemModel[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Subdivision/GetBranchManagers/GetBranchManagers';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class TeacherService {
  /**
   *
   */
  static getCourses(options: IRequestOptions = {}): Promise<CourseItemModel[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/Teacher/GetCourses/GetCourses';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class UserService {
  /**
   *
   */
  static getUsers(options: IRequestOptions = {}): Promise<UserItemModel[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/User/GetUsers/GetUsers';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getByIdStudent(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AddEditStudentModel> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/User/GetById/GetByIdStudent';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static addStudent(
    params: {
      /** requestBody */
      body?: AddEditStudentModel;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserItemModel> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/User/AddStudent/AddStudent';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static editStudent(
    params: {
      /** requestBody */
      body?: AddEditStudentModel;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserItemModel> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/User/EditStudent/EditStudent';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getByIdUser(
    params: {
      /**  */
      id?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AddEditUserModel> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/User/GetByIdUser/GetByIdUser';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { id: params['id'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static addUser(
    params: {
      /** requestBody */
      body?: AddEditUserModel;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserItemModel> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/User/AddUser/AddUser';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static editUser(
    params: {
      /** requestBody */
      body?: AddEditUserModel;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<UserItemModel> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/User/EditUser/EditUser';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class AddEditStudentModel {
  /**  */
  'Id'?: number;

  /**  */
  'FirstName'?: string;

  /**  */
  'LastName'?: string;

  /**  */
  'MiddleName'?: string;

  /**  */
  'Sex'?: boolean;

  /**  */
  'BirthDate'?: Date;

  /**  */
  'Phone'?: string;

  /**  */
  'ParentFullName'?: string;

  /**  */
  'InformationSource'?: string;

  /**  */
  'PlaceOfStudyWork'?: string;

  /**  */
  'Password'?: string;

  /**  */
  'UserName'?: string;

  /**  */
  'StageOfEducation'?: number;

  constructor(data: undefined | any = {}) {
    this['Id'] = data['Id'];
    this['FirstName'] = data['FirstName'];
    this['LastName'] = data['LastName'];
    this['MiddleName'] = data['MiddleName'];
    this['Sex'] = data['Sex'];
    this['BirthDate'] = data['BirthDate'];
    this['Phone'] = data['Phone'];
    this['ParentFullName'] = data['ParentFullName'];
    this['InformationSource'] = data['InformationSource'];
    this['PlaceOfStudyWork'] = data['PlaceOfStudyWork'];
    this['Password'] = data['Password'];
    this['UserName'] = data['UserName'];
    this['StageOfEducation'] = data['StageOfEducation'];
  }

  public static validationModel = {
    FirstName: { required: true },
    LastName: { required: true },
    MiddleName: { required: true },
    BirthDate: { required: true },
    Phone: { required: true },
    ParentFullName: { required: true },
    InformationSource: { required: true },
    PlaceOfStudyWork: { required: true },
    Password: { required: true },
    UserName: { required: true }
  };
}

export class AddEditUserModel {
  /**  */
  'Id'?: number;

  /**  */
  'FirstName'?: string;

  /**  */
  'LastName'?: string;

  /**  */
  'MiddleName'?: string;

  /**  */
  'Sex'?: boolean;

  /**  */
  'BirthDate'?: Date;

  /**  */
  'Phone'?: string;

  /**  */
  'Password'?: string;

  /**  */
  'ΕmployeeDocuments'?: EmployeeDocumentModel[];

  /**  */
  'Role'?: string;

  /**  */
  'UserName'?: string;

  /**  */
  'UserCustomRights'?: UserCustomRightModel[];

  constructor(data: undefined | any = {}) {
    this['Id'] = data['Id'];
    this['FirstName'] = data['FirstName'];
    this['LastName'] = data['LastName'];
    this['MiddleName'] = data['MiddleName'];
    this['Sex'] = data['Sex'];
    this['BirthDate'] = data['BirthDate'];
    this['Phone'] = data['Phone'];
    this['Password'] = data['Password'];
    this['ΕmployeeDocuments'] = data['ΕmployeeDocuments'];
    this['Role'] = data['Role'];
    this['UserName'] = data['UserName'];
    this['UserCustomRights'] = data['UserCustomRights'];
  }

  public static validationModel = {};
}

export class AnswerModel {
  /**  */
  'Id'?: number;

  /**  */
  'QuestionId'?: number;

  /**  */
  'Text'?: string;

  /**  */
  'AnswerFileId'?: number;

  /**  */
  'FileContent'?: string;

  /**  */
  'FileName'?: string;

  /**  */
  'FileId'?: number;

  /**  */
  'InnerId'?: number;

  /**  */
  'IsCorrect'?: boolean;

  constructor(data: undefined | any = {}) {
    this['Id'] = data['Id'];
    this['QuestionId'] = data['QuestionId'];
    this['Text'] = data['Text'];
    this['AnswerFileId'] = data['AnswerFileId'];
    this['FileContent'] = data['FileContent'];
    this['FileName'] = data['FileName'];
    this['FileId'] = data['FileId'];
    this['InnerId'] = data['InnerId'];
    this['IsCorrect'] = data['IsCorrect'];
  }

  public static validationModel = {};
}

export class CourseItemModel {
  /**  */
  'Id'?: number;

  /**  */
  'Title'?: string;

  /**  */
  'FilePath'?: string;

  /**  */
  'Type'?: string;

  /**  */
  'ImageContent'?: string;

  constructor(data: undefined | any = {}) {
    this['Id'] = data['Id'];
    this['Title'] = data['Title'];
    this['FilePath'] = data['FilePath'];
    this['Type'] = data['Type'];
    this['ImageContent'] = data['ImageContent'];
  }

  public static validationModel = {};
}

export class CourseItemModelWithStat {
  /**  */
  'Id'?: number;

  /**  */
  'Title'?: string;

  /**  */
  'Type'?: string;

  /**  */
  'PassingPercentage'?: number;

  /**  */
  'ImageContent'?: string;

  constructor(data: undefined | any = {}) {
    this['Id'] = data['Id'];
    this['Title'] = data['Title'];
    this['Type'] = data['Type'];
    this['PassingPercentage'] = data['PassingPercentage'];
    this['ImageContent'] = data['ImageContent'];
  }

  public static validationModel = {};
}

export class CourseModel {
  /**  */
  'Id'?: number;

  /**  */
  'Title'?: string;

  /**  */
  'DBFileId'?: number;

  /**  */
  'File'?: DBFileModel;

  /**  */
  'Lessons'?: LessonModel[];

  /**  */
  'Tests'?: TestModel[];

  /**  */
  'StageOfEducation'?: number;

  constructor(data: undefined | any = {}) {
    this['Id'] = data['Id'];
    this['Title'] = data['Title'];
    this['DBFileId'] = data['DBFileId'];
    this['File'] = data['File'];
    this['Lessons'] = data['Lessons'];
    this['Tests'] = data['Tests'];
    this['StageOfEducation'] = data['StageOfEducation'];
  }

  public static validationModel = {};
}

export class CreateCourseCommand {
  /**  */
  'Id'?: number;

  /**  */
  'Title'?: string;

  /**  */
  'FileContent'?: string;

  /**  */
  'FileName'?: string;

  /**  */
  'FileType'?: string;

  /**  */
  'FileId'?: number;

  /**  */
  'DBFileId'?: number;

  constructor(data: undefined | any = {}) {
    this['Id'] = data['Id'];
    this['Title'] = data['Title'];
    this['FileContent'] = data['FileContent'];
    this['FileName'] = data['FileName'];
    this['FileType'] = data['FileType'];
    this['FileId'] = data['FileId'];
    this['DBFileId'] = data['DBFileId'];
  }

  public static validationModel = {};
}

export class DBFileModel {
  /**  */
  'Id'?: number;

  /**  */
  'FileName'?: string;

  /**  */
  'FilePath'?: string;

  constructor(data: undefined | any = {}) {
    this['Id'] = data['Id'];
    this['FileName'] = data['FileName'];
    this['FilePath'] = data['FilePath'];
  }

  public static validationModel = {};
}

export class EditCourseCommand {
  /**  */
  'Id'?: number;

  /**  */
  'Title'?: string;

  /**  */
  'FileContent'?: string;

  /**  */
  'FileName'?: string;

  /**  */
  'FileType'?: string;

  /**  */
  'FileId'?: number;

  /**  */
  'DBFileId'?: number;

  /**  */
  'FilePath'?: string;

  /**  */
  'Type'?: string;

  /**  */
  'Lessons'?: LessonModel[];

  /**  */
  'Tests'?: TestModel[];

  constructor(data: undefined | any = {}) {
    this['Id'] = data['Id'];
    this['Title'] = data['Title'];
    this['FileContent'] = data['FileContent'];
    this['FileName'] = data['FileName'];
    this['FileType'] = data['FileType'];
    this['FileId'] = data['FileId'];
    this['DBFileId'] = data['DBFileId'];
    this['FilePath'] = data['FilePath'];
    this['Type'] = data['Type'];
    this['Lessons'] = data['Lessons'];
    this['Tests'] = data['Tests'];
  }

  public static validationModel = {};
}

export class EditCourseModel {
  /**  */
  'Id'?: number;

  /**  */
  'Title'?: string;

  /**  */
  'FileContent'?: string;

  /**  */
  'FileName'?: string;

  /**  */
  'FileType'?: string;

  /**  */
  'FileId'?: number;

  /**  */
  'DBFileId'?: number;

  /**  */
  'FilePath'?: string;

  /**  */
  'Type'?: string;

  /**  */
  'Lessons'?: LessonModel[];

  /**  */
  'Tests'?: TestModel[];

  constructor(data: undefined | any = {}) {
    this['Id'] = data['Id'];
    this['Title'] = data['Title'];
    this['FileContent'] = data['FileContent'];
    this['FileName'] = data['FileName'];
    this['FileType'] = data['FileType'];
    this['FileId'] = data['FileId'];
    this['DBFileId'] = data['DBFileId'];
    this['FilePath'] = data['FilePath'];
    this['Type'] = data['Type'];
    this['Lessons'] = data['Lessons'];
    this['Tests'] = data['Tests'];
  }

  public static validationModel = {};
}

export class EmployeeDocumentModel {
  /**  */
  'Id'?: number;

  /**  */
  'UserId'?: number;

  /**  */
  'DocumentType'?: EmployeeDocumentTypeEnum;

  /**  */
  'DBFileId'?: number;

  /**  */
  'FileContent'?: string;

  /**  */
  'FileName'?: string;

  /**  */
  'FileId'?: number;

  /**  */
  'UniqueNumber'?: string;

  /**  */
  'IsDeleted'?: boolean;

  constructor(data: undefined | any = {}) {
    this['Id'] = data['Id'];
    this['UserId'] = data['UserId'];
    this['DocumentType'] = data['DocumentType'];
    this['DBFileId'] = data['DBFileId'];
    this['FileContent'] = data['FileContent'];
    this['FileName'] = data['FileName'];
    this['FileId'] = data['FileId'];
    this['UniqueNumber'] = data['UniqueNumber'];
    this['IsDeleted'] = data['IsDeleted'];
  }

  public static validationModel = {};
}

export class ExecuteLessonModel {
  /**  */
  'Id'?: number;

  /**  */
  'Title'?: string;

  /**  */
  'HtmlContent'?: string;

  /**  */
  'PresentationLink'?: string;

  /**  */
  'VideoLink'?: string;

  /**  */
  'CourseId'?: number;

  /**  */
  'NextLessonId'?: number;

  constructor(data: undefined | any = {}) {
    this['Id'] = data['Id'];
    this['Title'] = data['Title'];
    this['HtmlContent'] = data['HtmlContent'];
    this['PresentationLink'] = data['PresentationLink'];
    this['VideoLink'] = data['VideoLink'];
    this['CourseId'] = data['CourseId'];
    this['NextLessonId'] = data['NextLessonId'];
  }

  public static validationModel = {};
}

export class LessonItemModel {
  /**  */
  'Id'?: number;

  /**  */
  'Title'?: string;

  /**  */
  'Available'?: boolean;

  /**  */
  'Type'?: LessonItemTypeEnum;

  /**  */
  'Status'?: LessonItemStatusEnum;

  /**  */
  'StatusDescription'?: string;

  constructor(data: undefined | any = {}) {
    this['Id'] = data['Id'];
    this['Title'] = data['Title'];
    this['Available'] = data['Available'];
    this['Type'] = data['Type'];
    this['Status'] = data['Status'];
    this['StatusDescription'] = data['StatusDescription'];
  }

  public static validationModel = {};
}

export class LessonModel {
  /**  */
  'Id'?: number;

  /**  */
  'CourseId'?: number;

  /**  */
  'Title'?: string;

  /**  */
  'HtmlContent'?: string;

  /**  */
  'PresentationLink'?: string;

  /**  */
  'VideoLink'?: string;

  /**  */
  'Questions'?: QuestionModel[];

  /**  */
  'Tests'?: TestModel[];

  /**  */
  'InnerId'?: number;

  constructor(data: undefined | any = {}) {
    this['Id'] = data['Id'];
    this['CourseId'] = data['CourseId'];
    this['Title'] = data['Title'];
    this['HtmlContent'] = data['HtmlContent'];
    this['PresentationLink'] = data['PresentationLink'];
    this['VideoLink'] = data['VideoLink'];
    this['Questions'] = data['Questions'];
    this['Tests'] = data['Tests'];
    this['InnerId'] = data['InnerId'];
  }

  public static validationModel = {};
}

export class LoginCommand {
  /**  */
  'UserName'?: string;

  /**  */
  'Password'?: string;

  constructor(data: undefined | any = {}) {
    this['UserName'] = data['UserName'];
    this['Password'] = data['Password'];
  }

  public static validationModel = {};
}

export class LoginResponse {
  /**  */
  'Bearer'?: string;

  /**  */
  'Error'?: string;

  /**  */
  'FIO'?: string;

  /**  */
  'Photo'?: string;

  constructor(data: undefined | any = {}) {
    this['Bearer'] = data['Bearer'];
    this['Error'] = data['Error'];
    this['FIO'] = data['FIO'];
    this['Photo'] = data['Photo'];
  }

  public static validationModel = {};
}

export class QuestionModel {
  /**  */
  'Id'?: number;

  /**  */
  'Title'?: string;

  /**  */
  'TitlePictureId'?: number;

  /**  */
  'QuestionType'?: QuestionTypeEnum;

  /**  */
  'LessonId'?: number;

  /**  */
  'Answers'?: AnswerModel[];

  /**  */
  'FileContent'?: string;

  /**  */
  'FileName'?: string;

  /**  */
  'FileId'?: number;

  /**  */
  'InnerId'?: number;

  constructor(data: undefined | any = {}) {
    this['Id'] = data['Id'];
    this['Title'] = data['Title'];
    this['TitlePictureId'] = data['TitlePictureId'];
    this['QuestionType'] = data['QuestionType'];
    this['LessonId'] = data['LessonId'];
    this['Answers'] = data['Answers'];
    this['FileContent'] = data['FileContent'];
    this['FileName'] = data['FileName'];
    this['FileId'] = data['FileId'];
    this['InnerId'] = data['InnerId'];
  }

  public static validationModel = {};
}

export class RoleRightModel {
  /**  */
  'Role'?: string;

  /**  */
  'Right'?: string;

  /**  */
  'Checked'?: boolean;

  constructor(data: undefined | any = {}) {
    this['Role'] = data['Role'];
    this['Right'] = data['Right'];
    this['Checked'] = data['Checked'];
  }

  public static validationModel = {};
}

export class ScheduleData {
  /**  */
  'Items'?: ScheduleModel[];

  /**  */
  'Teachers'?: UserItemModel[];

  /**  */
  'Students'?: UserItemModel[];

  /**  */
  'Courses'?: CourseItemModel[];

  constructor(data: undefined | any = {}) {
    this['Items'] = data['Items'];
    this['Teachers'] = data['Teachers'];
    this['Students'] = data['Students'];
    this['Courses'] = data['Courses'];
  }

  public static validationModel = {};
}

export class ScheduleModel {
  /**  */
  'Id'?: number;

  /**  */
  'DayOfWeek'?: DayOfWeekEnum;

  /**  */
  'StartTime'?: string;

  /**  */
  'Duration'?: string;

  /**  */
  'SubdivisionId'?: number;

  /**  */
  'TeacherId'?: number;

  /**  */
  'SubdivisionAddress'?: string;

  /**  */
  'TeacherFio'?: string;

  /**  */
  'Students'?: ScheduleStudentModel[];

  constructor(data: undefined | any = {}) {
    this['Id'] = data['Id'];
    this['DayOfWeek'] = data['DayOfWeek'];
    this['StartTime'] = data['StartTime'];
    this['Duration'] = data['Duration'];
    this['SubdivisionId'] = data['SubdivisionId'];
    this['TeacherId'] = data['TeacherId'];
    this['SubdivisionAddress'] = data['SubdivisionAddress'];
    this['TeacherFio'] = data['TeacherFio'];
    this['Students'] = data['Students'];
  }

  public static validationModel = {};
}

export class ScheduleStudentModel {
  /**  */
  'Id'?: number;

  /**  */
  'ScheduleId'?: number;

  /**  */
  'StudentId'?: number;

  /**  */
  'CourseId'?: number;

  /**  */
  'FirstName'?: string;

  /**  */
  'LastName'?: string;

  /**  */
  'MiddleName'?: string;

  /**  */
  'StageOfEducation'?: number;

  constructor(data: undefined | any = {}) {
    this['Id'] = data['Id'];
    this['ScheduleId'] = data['ScheduleId'];
    this['StudentId'] = data['StudentId'];
    this['CourseId'] = data['CourseId'];
    this['FirstName'] = data['FirstName'];
    this['LastName'] = data['LastName'];
    this['MiddleName'] = data['MiddleName'];
    this['StageOfEducation'] = data['StageOfEducation'];
  }

  public static validationModel = {};
}

export class SubdivisionModel {
  /**  */
  'Id'?: number;

  /**  */
  'Address'?: string;

  /**  */
  'BranchManagerFio'?: string;

  /**  */
  'BranchManagerId'?: number;

  constructor(data: undefined | any = {}) {
    this['Id'] = data['Id'];
    this['Address'] = data['Address'];
    this['BranchManagerFio'] = data['BranchManagerFio'];
    this['BranchManagerId'] = data['BranchManagerId'];
  }

  public static validationModel = {};
}

export class TestModel {
  /**  */
  'Id'?: number;

  /**  */
  'Title'?: string;

  /**  */
  'TestPosition'?: TestPositionEnum;

  /**  */
  'CourseId'?: number;

  /**  */
  'LessonId'?: number;

  /**  */
  'AmountQuestions'?: number;

  /**  */
  'InnerId'?: number;

  constructor(data: undefined | any = {}) {
    this['Id'] = data['Id'];
    this['Title'] = data['Title'];
    this['TestPosition'] = data['TestPosition'];
    this['CourseId'] = data['CourseId'];
    this['LessonId'] = data['LessonId'];
    this['AmountQuestions'] = data['AmountQuestions'];
    this['InnerId'] = data['InnerId'];
  }

  public static validationModel = {};
}

export class UserCustomRightModel {
  /**  */
  'Id'?: number;

  /**  */
  'UserId'?: number;

  /**  */
  'Right'?: string;

  /**  */
  'Checked'?: boolean;

  constructor(data: undefined | any = {}) {
    this['Id'] = data['Id'];
    this['UserId'] = data['UserId'];
    this['Right'] = data['Right'];
    this['Checked'] = data['Checked'];
  }

  public static validationModel = {};
}

export class UserItemModel {
  /**  */
  'Id'?: number;

  /**  */
  'FirstName'?: string;

  /**  */
  'LastName'?: string;

  /**  */
  'MiddleName'?: string;

  /**  */
  'Role'?: string;

  /**  */
  'FIO'?: string;

  /**  */
  'BirthDate'?: Date;

  /**  */
  'StageOfEducation'?: number;

  constructor(data: undefined | any = {}) {
    this['Id'] = data['Id'];
    this['FirstName'] = data['FirstName'];
    this['LastName'] = data['LastName'];
    this['MiddleName'] = data['MiddleName'];
    this['Role'] = data['Role'];
    this['FIO'] = data['FIO'];
    this['BirthDate'] = data['BirthDate'];
    this['StageOfEducation'] = data['StageOfEducation'];
  }

  public static validationModel = {};
}

export type DayOfWeekEnum = 1 | 2 | 3 | 4 | 5 | 6 | 7;

export type EmployeeDocumentTypeEnum = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9;

export type LessonItemStatusEnum = 1 | 2 | 3 | 4;

export type LessonItemTypeEnum = 1 | 2;

export type QuestionTypeEnum = 1 | 2 | 3;

export type TestPositionEnum = 1 | 2 | 3 | 4;
