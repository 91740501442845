



























import { Component, Vue } from "vue-property-decorator";
import { Field } from "@/interfaces/Table";
import {
  SubdivisionModel,
  SubdivisionService,
  UserItemModel,
} from "@/services/service";
import AddSubdivision from "@/views/Management/Subdivision/AddSubdivision.vue";
import { RoleList } from "@/constants/auth";

@Component({ components: { AddSubdivision } })
export default class SubdivisionList extends Vue {
  private items: SubdivisionModel[] = [];
  private managers: UserItemModel[] = [];
  private dialog = false;
  private subdivisionDialog = false;
  private subdivisionModel = {} as SubdivisionModel;

  private addsubdivision() {
    this.subdivisionModel = {};
    this.subdivisionDialog = true;
  }

  private subdivisionCanceled() {
    this.subdivisionDialog = false;
  }

  public async editsubdivision(item: SubdivisionModel) {
    const model = await SubdivisionService.getById({
      id: item.Id,
    });
    this.subdivisionModel = model;
    this.subdivisionDialog = true;
  }
  subdivisionAdded(item) {
    this.items.push(item);
    this.subdivisionDialog = false;
  }

  subdivisionChanged(item) {
    const items = this.items.filter((i) => i.Id != item.Id);
    items.push(item);
    this.items = items;
    this.subdivisionDialog = false;
  }

  get getHeaders() {
    const headers: Array<Field> = [
      { text: "Адрес", value: "Address", sortable: false },
      { text: "Руководитель", value: "BranchManagerFio", sortable: false },
    ];
    return headers;
  }

  async created() {
    await this.getData();
  }

  private async getData() {
    this.items = await SubdivisionService.getSubdivisions();
    this.managers = await SubdivisionService.getBranchManagers();
  }
}
