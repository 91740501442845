










































import { SubdivisionModel, SubdivisionService, UserItemModel } from "@/services/service";
import { Component, Vue, Watch, Prop } from "vue-property-decorator";

@Component
export default class AddSubdivision extends Vue {
  @Prop({ required: true }) managers!: UserItemModel[];
  private dialog = true;
  @Prop({ required: true }) model?: SubdivisionModel;

  get Title() {
    return this.model!.Id ? "Добавить подразделение" : "Изменить подразделение";
  }

  @Watch("dialog")
  onDialogChanged(val: boolean) {
    if (!val) {
      this.$emit("cancel");
    }
  }

  async save() {
    const action = this.isAdded ? SubdivisionService.createSubdivision : SubdivisionService.updateSubdivision;
    const result = await action({ body: this.model });
    this.$emit(this.isAdded ? "added" : "changed", result);
  }
  private cancel() {
    this.$emit("cancel");
  }
   get isAdded() {
    return this.model!.Id == null;
  }
}
