























import { Field } from "@/interfaces/Table";
import { UserItemModel } from "@/services/service";
import { Component, Vue, Prop } from "vue-property-decorator";
import { formatDate } from "@/helpers/index";
@Component({})
export default class ListOfStudents extends Vue {
  @Prop({ required: true }) students!: UserItemModel[];
  @Prop({ required: true }) showSelect!: boolean;
  @Prop() selected!: [];
  @Prop() showDelete!: boolean;
  private selectedStudents = [];
  private formatDate = formatDate;
  get getHeaders() {
    const headers: Array<Field> = [
      { text: "Фамилия", value: "LastName", sortable: false },
      { text: "Имя", value: "FirstName", sortable: false },
      { text: "Отчество", value: "MiddleName", sortable: false },
      { text: "Дата рождения", value: "BirthDate", sortable: false },
      { text: "Ступень обучения", value: "StageOfEducation", sortable: false}
    ];
    if (this.showDelete) {
      headers.push({ text: "", value: "action" });
    }

    return headers;
  }

  
  public getSelectedStudents() {  
    return this.selectedStudents;
  }
  created() {
    this.selectedStudents = this.selected;
  }

  deleteStudent(item) {
    this.$emit("studentDeleted", item);
  } 
}
