import { isNumber, isString, valuesIn } from "lodash";
import moment from "moment";
//import {EMPTY_STRING} from "@/constants/value-constants";

export function parseJwt(token: string) {
    if (!token) {
        return;
    }

    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    return JSON.parse(window.atob(base64));
}

export function getDateFromDateTime(date: Date): string {
    return date.toString().substring(0, 10);
}

export const formatDecimal = (value, decimalLength) => {
    if (!isNumber(value)) {
        return value;
    }
    
    return value.toFixed(decimalLength).replace(".", ",");
}

export const ToNumberWithSpaces = (value, decimalLength) => {
    if (!isNumber(value)) {
        return value;
    }
    if(decimalLength > 0){
        return value.toLocaleString(undefined, { minimumFractionDigits: decimalLength, maximumFractionDigits: decimalLength});
    }else {
        return value.toLocaleString();
    }
}

export function formatDateTime(value) {
    if (isNaN(Date.parse(value))) {
        return value;
    }
    return moment(String(value)).format("DD.MM.YYYY HH:mm");
}

export function formatDate(value) {
    if (isNaN(Date.parse(value))) {
        return value;
    }
    return moment(String(value)).format("DD.MM.YYYY");
}

export function sleep (ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export function buildUniqueId(): number {
    return Date.now();
}

// export function isNullOrEmpty(value: string | null | undefined): boolean {
//     return value == null
//         || value == undefined
//         || value.trim() === EMPTY_STRING;
// }
