import { toNumber } from "lodash";

export const EMAIL_RULE = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

/**
 * The returned function makes sure the passed value is a valid email address.
 * Returns the error message otherwise.
 *
 * @param {string} message
 * @returns {function(*): (boolean|string)}
 */
export const email = (message = "Неверный формат электронной почты") => {
    if (!message) {
        message;
    }

    return v => {
        return !v || EMAIL_RULE.test(v) || message;
    };
};

/**
 * The returned function makes sure the passed value is a valid phone number.
 * Returns the error message otherwise.
 *
 * @param {string} message
 * @returns {function(*): (boolean|string)}
 */
export const phone = (message: string): any => {
    if (!message) {
        message = "Invalid phone number format";
    }

    return v => {
        return /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im.test(v) || message;
    };
};

/**
 * Validator that ensures the value in a field doesn't exceed a predefined length limit.
 * Returns the error message otherwise.
 *
 * @param {number} length The maximum length allowed for the field's value
 * @param {string} message
 * @returns {function(*): (boolean|string)}
 */
export const maxLength = (length: number, message = "") => {
    if (!message) {
        message = `Максимальное кол-во символов ${length}`;
    }

    return v => {
        return v && v.length > length ? message : true;
    };
};

/**
 * Validator that ensures the value in a field doesn't go below a predefined length limit.
 * Returns the error message otherwise.
 *
 * @param {number} length The minimum length allowed for the field's value
 * @param {string} message
 * @returns {function(*): (boolean|string)}
 */
export const minLength = (length: number, message = "") => {
    if (!message) {
        message = `Минимальное кол-во символов ${length}`;
    }

    return v => {
        return v && v.length < length ? message : true;
    };
};

/**
 * Validator that ensures the field value is not empty.
 * Returns the error message otherwise.
 *
 * @param message
 * @returns {function(*=): boolean}
 */
export const required = (message = "Это поле обязательно") => {
    return v => {
        const result = !v || (v.length != undefined && v.length < 1) ? message : true;
        return result;
    };
};

/**
 * Ensures the username is only composed of alpha-numeric characters and underscores.
 * Returns the error message otherwise.
 *
 * @param message
 * @returns {function(*=): boolean}
 */
export const username = (message = "Only letters, numbers and underscore are allowed."): any => {
    return v => {
        return /^[a-zA-Z0-9_]+$/.test(v) ? true : message;
    };
};

/**
 * Ensures the username is only composed of alpha-numeric characters and underscores.
 * Returns the error message otherwise.
 *
 * @param message
 * @returns {function(*=): boolean}
 */
export const numbers = (message = "Допускаются только числа") => {
    return v => {
        return !v || (v.length && v.length < 1) || /^[0-9]+$/.test(v) ? true : message;
    };
};

export const nonZero = (message = "Значение должно быть отлично от нуля") => {
    return v => {
        return toNumber(v) ? true : message;
    };
};

export interface ValidationModel {
    maxLength?: number | undefined;
    required?: boolean | undefined;
}
export const getRules = (validationModel: ValidationModel): Array<unknown> => {
    const rules: Array<unknown> = [];

    if (validationModel.maxLength) {
        rules.push(maxLength(validationModel.maxLength));
    }

    if (validationModel.required) {
        rules.push(required());
    }

    return rules;
};
