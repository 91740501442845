export enum QuestionTypeEnum {
  /// <summary>
  /// Открытый вопрос
  /// </summary>
  Open = 1,

  /// <summary>
  /// Один ответ
  /// </summary>
  OneAnswer = 2,

  /// <summary>
  /// Несколько ответов
  /// </summary>
  MultiAnswers = 3,
}

export const QuestionTypeList = [
  { value: QuestionTypeEnum.Open, name: "Открытый вопрос" },
  { value: QuestionTypeEnum.OneAnswer, name: "Один ответ" },
  { value: QuestionTypeEnum.MultiAnswers, name: "Несколько ответов" },
];

export enum TestPositionEnum {
  /// <summary>
  /// Перед курсом
  /// </summary>
  BeforeCourse = 1,

  /// <summary>
  /// После курса
  /// </summary>
  AfterCourse = 2,

  /// <summary>
  /// Перед уроком
  /// </summary>
  BeforeLesson = 3,

  /// <summary>
  /// После урока
  /// </summary>
  AfterLesson = 4,
}

export const TestPositionList = [
  { value: TestPositionEnum.BeforeCourse, name: "Перед курсом" },
  { value: TestPositionEnum.AfterCourse, name: "После курса" },
  { value: TestPositionEnum.BeforeLesson, name: "Перед уроком" },
  { value: TestPositionEnum.AfterLesson, name: "После урока" },
];
