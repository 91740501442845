













import { CourseItemModel, TeacherService } from "@/services/service";
import { Component, Vue } from "vue-property-decorator";

@Component({ components: {} })
export default class TeacherDesktop extends Vue {
    private courses: CourseItemModel[] = [];

    async created() {
        this.courses = await TeacherService.getCourses();
    }

    goToCourse(id) {
        this.$router.push({ name: "TeacherCourse", params: { id: id } });
    }
}
