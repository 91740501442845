import "@/initialComponents";
import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import axios from "@/services/Axios";
import { serviceOptions } from "@/services/service";
import store from "./store";
import moment from "moment";
import { VueMaskDirective } from 'v-mask';


Vue.config.productionTip = false;
serviceOptions.axios = axios;
Vue.prototype.moment = moment;
Vue.directive('mask', VueMaskDirective);
new Vue({
    vuetify,
    router,
    store,
    render: h => h(App)
}).$mount("#app");

export default App;