




























import { Component, Vue } from "vue-property-decorator";
import { Field } from "@/interfaces/Table";
import { CourseItemModel, ScheduleModel, ScheduleService, SubdivisionModel, SubdivisionService, UserItemModel } from "@/services/service";
import AddSchedule from "@/views/Management/Schedule/AddSchedule.vue";
import { DayOfWeekList } from "@/constants/common";

@Component({ components: { AddSchedule } })
export default class ScheduleList extends Vue {
    private items: ScheduleModel[] = [];
    private teachers: UserItemModel[] = [];
    private students: UserItemModel[] = [];
    private subdivisions: SubdivisionModel[] = [];
    private courses: CourseItemModel[] = [];
    private dialog = false;
    private scheduleDialog = false;
    private scheduleModel = {} as ScheduleModel;

    private addschedule() {
        this.scheduleModel = { Students: [] };
        this.scheduleDialog = true;
    }

    private scheduleCanceled() {
        this.scheduleDialog = false;
    }

    public async editSchedule(item: ScheduleModel) {
        const model = await ScheduleService.getById({
            id: item.Id,
        });
        this.scheduleModel = model;
        this.scheduleDialog = true;
    }
    scheduleAdded(item) {
        this.items.push(item);
        this.scheduleDialog = false;
    }

    scheduleChanged(item) {
        const items = this.items.filter((i) => i.Id != item.Id);
        items.push(item);
        this.items = items;
        this.scheduleDialog = false;
    }

    get getHeaders() {
        const headers: Array<Field> = [
            { text: "Филиал", value: "SubdivisionAddress", sortable: false },
            { text: "Преподаватель", value: "TeacherFio", sortable: false },
            { text: "День недели", value: "DayOfWeek", sortable: false },
            { text: "Время начала", value: "StartTime", sortable: false },
            { text: "Продолжительность", value: "Duration", sortable: false },
        ];
        return headers;
    }

    async created() {
        await this.getData();
    }

    private async getData() {
        const data = await ScheduleService.getData();
        this.items = data.Items ?? [];
        this.teachers = data.Teachers ?? [];
        this.students = data.Students ?? [];
        this.courses = data.Courses ?? [];
        this.subdivisions = await SubdivisionService.getSubdivisions();
    }
    getDayOfWeekTitle(item) {
        const day = DayOfWeekList.find((i) => i.value == item.DayOfWeek);
        return day!.text;
    }
}
