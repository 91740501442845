






























import { Component, Vue } from "vue-property-decorator";
import Header from "./layouts/Header.vue";
import { Menu } from "@/constants/menu";
@Component({
    components: {
        Header,
    },
})
export default class App extends Vue {
    private drawer = false;
    private group = 1;
    //private menuItems = [];
    get disableHeader() {
        return this.$route.meta != null ? this.$route.meta.disableHeader : false;
    }
    menuClicked(value) {
        this.drawer = value;
    }

    goto(item) {
        this.$router.push({ name: item.route });
    }
    get hideMenuButton() {
        return this.menuItems.length == 0;
    }

    get menuItems() {
        if (this.$store.getters.isAuthenticated) {
            const rights = this.$store.getters.rights as any;
            if (rights) return Menu.filter((q) => rights.indexOf(q.right!) > -1) as [];
        }
        return [];
    }
}
