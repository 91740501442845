





















































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { LessonModel, QuestionModel } from "@/services/service";
import { Field } from "@/interfaces/Table";
import AddEditQuestion from "@/views/Courses/Questions/AddEditQuestion.vue";
import { cloneDeep } from "lodash";
import { saveToArray, maxId } from "@/helpers/arrayHelper";
@Component({ components: { AddEditQuestion } })
export default class AddEditLesson extends Vue {
  @Prop({ default: true }) model?: LessonModel;
  private dialog = true;
  private addEditQuestion: QuestionModel | undefined = undefined;
  private showAddEditQuestion = false;
  private questionsLength = this.model?.Questions!.length;
  private getTitle(): string {
    return "Добавить урок";
  }

  private save() {
    this.$emit("saved", this.model);
  }

  private cancel() {
    this.$emit("canceled");
  }

  get getHeaders() {
    const headers: Array<Field> = [
      { text: "Название", value: "Title", sortable: false },
    ];
    return headers;
  }

  @Watch("dialog")
  dialogChanged(val) {
    if (!val) {
      this.$emit("canceled");
    }
  }

  showQuestion(val: QuestionModel) {
    this.addEditQuestion = cloneDeep(val);
    this.showAddEditQuestion = true;
  }

  addQuestion() {
    this.addEditQuestion = new QuestionModel();
    this.addEditQuestion.InnerId = maxId(this.model!.Questions!);
    this.addEditQuestion.Answers = [];
    this.showAddEditQuestion = true;
  }

  saveQuestion(val: QuestionModel) {
    const newItems = saveToArray(this.model?.Questions!, val);

    this.model!.Questions! = [];
    this.questionsLength = newItems.length + 1;
    this.$nextTick(() => {
      this.model!.Questions = newItems;
      this.questionsLength = newItems.length;
    });

    this.showAddEditQuestion = false;
  }

  cancelShowQuestion() {
    this.showAddEditQuestion = false;
  }
}
