

















































import { setFilePropertyToEntity } from "@/helpers/fileHelper";

import { EmployeeDocumentModel } from "@/services/service";
import { Component, Vue, Prop, Watch } from "vue-property-decorator";

import AddEditAnswer from "@/views/Courses/Answers/AddEditAnswer.vue";
import { DocumentTypeList } from "@/constants/common";
@Component({ components: { AddEditAnswer } })
export default class AddEditEmployeeDocument extends Vue {
  @Prop({ default: true }) model?: EmployeeDocumentModel;
  private dialog = true;
  private fileName = "";
  private currentFile: File | null = null;
  private DocumentTypeList = DocumentTypeList;

  get isAdded() {
    return this.model!.Id == null;
  }

  private getTitle(): string {
    return this.isAdded ? "Добавить документ" : "Изменить документ";
  }

  private save() {
    this.$emit("saved", this.model);
  }

  private cancel() {
    this.$emit("canceled");
  }

  @Watch("dialog")
  dialogChanged(val) {
    if (!val) {
      this.$emit("canceled");
    }
  }

  private async selectFile(file: File) {
    this.fileName = file?.name;
    await setFilePropertyToEntity(file, this.model);
    this.currentFile = file;
  }
}
