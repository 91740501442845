var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.showAddEditLesson)?_c('add-edit-lesson',{attrs:{"model":_vm.addEditLesson},on:{"saved":_vm.saveLesson,"canceled":_vm.cancelShowLesson}}):_vm._e(),(_vm.showAddEditTest)?_c('add-edit-test',{attrs:{"model":_vm.addEditTest,"lessons":_vm.model.Lessons},on:{"saved":_vm.saveTest,"canceled":_vm.cancelShowTest}}):_vm._e(),_c('div',{staticClass:"text-h5 lighten-2"},[_vm._v(" Редактировать курс ")]),_c('div',[_c('v-row',[_c('v-col',{staticStyle:{"display":"flex","flex-direction":"row","justify-content":"flex-start"},attrs:{"cols":12}},[(_vm.model.FileContent)?_c('img',{staticStyle:{"display":"inline-flex"},attrs:{"src":_vm.model.FileContent,"height":"48"}}):_vm._e(),_c('v-text-field',{staticStyle:{"display":"inline-flex","margin-left":"10px","padding-left":"10px"},attrs:{"title":"Название курса"},model:{value:(_vm.model.Title),callback:function ($$v) {_vm.$set(_vm.model, "Title", $$v)},expression:"model.Title"}})],1)],1),_c('div',[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.addLesson}},[_vm._v("Добавить урок")])],1),_c('div',[_c('v-data-table',{staticClass:"row-pointer",attrs:{"headers":_vm.getHeaders,"items":_vm.model.Lessons,"hide-default-footer":true,"item-key":"InnerId","server-items-length":_vm.model.Lessons.length},scopedSlots:_vm._u([{key:"item.Title",fn:function(ref){
var item = ref.item;
return [_c('a',{on:{"click":function($event){return _vm.showLesson(item)}}},[_vm._v(_vm._s(item.Title))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",on:{"click":function($event){return _vm.deleteLesson(item)}}},[_vm._v(_vm._s("mdi-delete"))])]}}],null,true)})],1),_c('div',[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.addTest}},[_vm._v("Добавить тест")])],1),_c('div',[_c('v-data-table',{staticClass:"row-pointer",attrs:{"headers":_vm.getHeaders,"items":_vm.model.Tests,"hide-default-footer":true,"item-key":"InnerId","server-items-length":_vm.model.Tests.length},scopedSlots:_vm._u([{key:"item.Title",fn:function(ref){
var item = ref.item;
return [_c('a',{on:{"click":function($event){return _vm.showTest(item)}}},[_vm._v(_vm._s(item.Title))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",on:{"click":function($event){return _vm.deleteTest(item)}}},[_vm._v(_vm._s("mdi-delete"))])]}}],null,true)})],1)],1),_c('v-divider'),_c('div',{staticStyle:{"width":"100%","display":"flex"}},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary mr-2"},on:{"click":_vm.save}},[_vm._v(" Сохранить ")]),_c('v-btn',{attrs:{"color":"blue lighten-5"},on:{"click":_vm.cancel}},[_vm._v(" Отмена ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }