import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Home from "../views/Home.vue";
import store from "../store";
import CourseList from "@/views/Courses/List.vue";
import EditCourse from "@/views/Courses/EditCourse.vue";
import Login from "@/views/Pages/Login.vue";
import UserList from "@/views/Users/List.vue";
import SubdivisionList from "@/views/Management/Subdivision/List.vue";
import ScheduleList from "@/views/Management/Schedule/ScheduleList.vue";
import StudentDektop from "@/views/Students/StudentDesktop.vue";
import StudentCourse from "@/views/Students/StudentCourse.vue";
import StudentLesson from "@/views/Students/StudentLesson.vue";
import TeacherDesktop from "@/views/Teacher/TeacherDesktop.vue";
import TeacherCourse from "@/views/Teacher/TeacherCourse.vue";
import TeacherDesktopLesson from "@/views/Teacher/TeacherDesktopLesson.vue";


const ifAuthenticated = (to, from, next) => {
    if (store.getters.isAuthenticated) {
        next();
        return;
    }
    next("/login");
};

const ifNotAuthenticated = (to, from, next) => {
    if (!store.getters.isAuthenticated || store.getters.isLogout) {
        next();
        return;
    }
    next("/");
};

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/courses",
    name: "CourseList",
    component: CourseList,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/login",
    name: "Login",
    meta: { layout: "full", title: "Вход", disableHeader: true },
    beforeEnter: ifNotAuthenticated,
    component: Login,
  
  },  
  {
    path: "/editCourse/:id",
    name: "EditCourse",
    component: EditCourse,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/users",
    name: "UserList",
    component: UserList,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/subdivisions",
    name: "SubdivisionList",
    component: SubdivisionList,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/schedule",
    name: "Schedule",
    component: ScheduleList,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/student-desktop",
    name: "StudentDesktop",  
    component: StudentDektop,
    beforeEnter: ifAuthenticated    
  },
  {
    path: "/student-desktop/course/:id",
    name: "StudentDesktopCourse",    
    component: StudentCourse,
    beforeEnter: ifAuthenticated
  },
  {
    path: "/student-desktop/lesson/:id",
    name: "StudentDesktopLesson",    
    component: StudentLesson,
    beforeEnter: ifAuthenticated
  },
  {
    path: "/",
    name: "Home",
    component: Home,
    beforeEnter: ifAuthenticated,
  }
  ,{
    path: "/teacher-desktop",
    name: "TeacherDesktop",
    component: TeacherDesktop,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/teacher-desktop/course/:id",
    name: "TeacherCourse",
    component: TeacherCourse,
    beforeEnter: ifAuthenticated,
  },
  {
    path: "/teacher-desktop/lesson/:id",
    name: "TeacherDesktopLesson",
    component: TeacherDesktopLesson,
    beforeEnter: ifAuthenticated
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
