







































import { AUTH_LOGOUT, AUTH_REQUEST } from "@/constants/auth";
import { FIO_KEY } from "@/constants/common";
import { Component, Vue, Prop } from "vue-property-decorator";
@Component
export default class Header extends Vue {
  @Prop() hideMenuButton!: boolean;
  private menu = false;
  get FIO() {
    return localStorage.getItem(FIO_KEY);
  }
  private openMenu() {
    this.menu = !this.menu;
    this.$emit("menu", this.menu);
  }

  private async logOut() {
    await this.$store.dispatch(AUTH_LOGOUT);
     this.$router.push({ name: "Login" });
  }
}
