import {
  AUTH_REQUEST,
  AUTH_ERROR,
  AUTH_SUCCESS,
  AUTH_LOGOUT,
  RoleEnum,
  ClaimEnum,
  AUTH_SET_LOGOUT,
} from "@/constants/auth";
import axios from "@/services/Axios";
import { AccountService, LoginResponse } from "@/services/service";
import { MutationTree, ActionTree, GetterTree } from "vuex";
import {
  TOKEN_KEY,
  IS_LOCAL_KEY,
  FIO_KEY,
  PHOTO_KEY,
} from "@/constants/common";
import { AuthState } from "./types/auth";
import { parseJwt } from "../../helpers";
import { UNAUTHORIZED_HTTP_CODE } from "@/constants/common";

// Состояния
const state: AuthState = {
  token: localStorage.getItem(TOKEN_KEY) || "",
  status: "",
  hasLoadedOnce: false,
  isLogout: false,
};

const getRole = (token: string) => {
  const info = parseJwt(token);
  if (info?.role) {
    if (info.role.indexOf(RoleEnum.Administrator.authtext) != -1) {
      return RoleEnum.Administrator.value;
    } else if (info.role.indexOf(RoleEnum.Supervisor.authtext) != -1) {
      return RoleEnum.Supervisor.value;
    } else if (info.role.indexOf(RoleEnum.Receptionist.authtext) != -1) {
      return RoleEnum.Receptionist.value;
    } else if (info.role.indexOf(RoleEnum.ChiefMethodist.authtext) != -1) {
      return RoleEnum.ChiefMethodist.value;
    } else if (info.role.indexOf(RoleEnum.Methodist.authtext) != -1) {
      return RoleEnum.Methodist.value;
    } else if (info.role.indexOf(RoleEnum.BranchManager.authtext) != -1) {
      return RoleEnum.BranchManager.value;
    } else if (info.role.indexOf(RoleEnum.Teacher.authtext) != -1) {
      return RoleEnum.Teacher.value;
    } else if (info.role.indexOf(RoleEnum.Student.authtext) != -1) {
      return RoleEnum.Student.value;
    } else if (info.role.indexOf(RoleEnum.Controller.authtext) != -1) {
      return RoleEnum.Controller.value;
    }
  }
  return 0;
};

const getClaim = (token: string, claim: string) => {
  const info = parseJwt(token);
  return claim in info;
};

const getClaimNumber = (token: string, claimName: string): number => {
  const claims = parseJwt(token);
  return claims[claimName] as number;
};
const getClaimFlag = (token: string, claimName: string): boolean => {
  const claims = parseJwt(token);
  return claims[claimName] === "true";
};
const getClaimValue = (token: string, claim: string) => {
  const info = parseJwt(token);
  return info[claim];
};

const getters: GetterTree<AuthState, AuthState> = {
  isAuthenticated: (state: AuthState): boolean => !!state.token,
  isLogout: (state: AuthState): boolean => state.isLogout,
  userName: (state: AuthState): string => {
    const info = parseJwt(state.token);
    if (info) {
      return info.unique_name;
    }
    return "undefined";
  },
  role: (state: AuthState): number => {
    return getRole(state.token);
  },
  isAdministrator: (state: AuthState): boolean =>
    getRole(state.token) === RoleEnum.Administrator.value,
  isSupervisor: (state: AuthState): boolean =>
    getRole(state.token) === RoleEnum.Supervisor.value,
  isReceptionist: (state: AuthState): boolean =>
    getRole(state.token) === RoleEnum.Receptionist.value,  
  isChiefMethodist: (state: AuthState): boolean =>
    getRole(state.token) === RoleEnum.ChiefMethodist.value,
  isMethodist: (state: AuthState): boolean =>
    getRole(state.token) === RoleEnum.Methodist.value,
  isBranchManager: (state: AuthState): boolean =>
    getRole(state.token) === RoleEnum.BranchManager.value,
  isTeacher: (state: AuthState): boolean =>
    getRole(state.token) === RoleEnum.Teacher.value,
  isStudent: (state: AuthState): boolean =>
    getRole(state.token) === RoleEnum.Student.value,
  isController: (state: AuthState): boolean =>
    getRole(state.token) === RoleEnum.Controller.value,
  userId: (state: AuthState): number =>
    getClaimNumber(state.token, ClaimEnum.UserData),
  rights: (state: AuthState): any => getClaimValue(state.token, ClaimEnum.Right),
};

const succcessAction = (response: LoginResponse, commit, resolve) => {
  if (response.Error) {
    throw {
      message: response.Error,
      errorCode: UNAUTHORIZED_HTTP_CODE,
    };
  }
  commit(AUTH_SUCCESS, response.Bearer);
  axios.defaults.headers.common["Authorization"] = "Bearer " + response.Bearer;
  localStorage.setItem(FIO_KEY, response.FIO!);
  localStorage.setItem(PHOTO_KEY, response.Photo!);
  if (response.Bearer) {
    localStorage.setItem(TOKEN_KEY, response.Bearer);
    resolve(response.Bearer);
  }
  const isLocal = getClaimFlag(response.Bearer!, ClaimEnum.IsLocal);
  if (isLocal) {
    localStorage.setItem(IS_LOCAL_KEY, "true");
  }
};

const errorAction = (err, commit, reject) => {
  commit(AUTH_ERROR);
  axios.defaults.headers.common["Authorization"] = "";
  localStorage.removeItem(TOKEN_KEY);
  if (err?.errorCode === UNAUTHORIZED_HTTP_CODE) {
    reject(err);
  } else {
    reject(new Error(err));
  }
};

// Мутации состояний
const mutations: MutationTree<AuthState> = {
  [AUTH_REQUEST]: (state: AuthState) => {
    state.status = "loading";
  },
  [AUTH_SUCCESS]: (state: AuthState, token: string) => {
    state.status = "success";
    state.token = token;
    state.hasLoadedOnce = true;
  },
  [AUTH_ERROR]: (state: AuthState) => {
    state.status = "error";
    state.hasLoadedOnce = true;
  },
  [AUTH_LOGOUT]: (state: AuthState) => {
    state.token = "";
    state.status = "";
    state.isLogout = false;
  },
  [AUTH_SET_LOGOUT]: (state: AuthState) => {
    state.isLogout = true;
  },
};

// Действия по вызову мутаций
const actions: ActionTree<AuthState, AuthState> = {
  [AUTH_REQUEST]: ({ commit }, loginAccountCommand) => {
    return new Promise((resolve, reject) => {
      commit(AUTH_REQUEST);
      AccountService.login({ body: loginAccountCommand })
        .then((response) => {
          succcessAction(response, commit, resolve);
        })
        .catch((err) => {
          errorAction(err, commit, reject);
        });
    });
  },
  [AUTH_LOGOUT]: ({ commit }) => {
    return new Promise((resolve, reject) => {
      axios.defaults.headers.common["Authorization"] = "";
      localStorage.removeItem(TOKEN_KEY);
      commit(AUTH_SET_LOGOUT);
      resolve({});
    });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
