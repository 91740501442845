export const AUTH_REQUEST = "AUTH_REQUEST";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_ERROR = "AUTH_ERROR";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const AUTH_SET_LOGOUT = "AUTH_SET_LOGOUT";

/*
 * Enum ролей пользователей
 */
export const RoleEnum = {
  Administrator: {
    value: 1,
    authtext: "Administrator",
    text: "Администратор системы",
  },
  Supervisor: { value: 2, authtext: "Supervisor", text: "Руководитель" },
  Receptionist: {
    value: 3,
    authtext: "Receptionist",
    text: "Администратор – ресепсионист ",
  },
  ChiefMethodist: {
    value: 4,
    authtext: "ChiefMethodist",
    text: "Главный методист",
  },
  Methodist: { value: 5, authtext: "Methodist", text: "Методист" },
  BranchManager: {
    value: 6,
    authtext: "BranchManager",
    text: "Руководитель филиала",
  },
  Teacher: { value: 7, authtext: "Teacher", text: "Преподаватель" },
  Student: { value: 8, authtext: "Student", text: "Ученик" },
  Controller: { value: 9, authtext: "Controller", text: "Контроллер" },
};

export const CreateUserRoleList = [
  RoleEnum.Administrator,
  RoleEnum.Supervisor,
  RoleEnum.Receptionist,
  RoleEnum.ChiefMethodist,
  RoleEnum.Methodist,
  RoleEnum.BranchManager,
  RoleEnum.Teacher,
  RoleEnum.Controller,
];

export const RoleList = [
  RoleEnum.Administrator,
  RoleEnum.Supervisor,
  RoleEnum.Receptionist,
  RoleEnum.ChiefMethodist,
  RoleEnum.Methodist,
  RoleEnum.BranchManager,
  RoleEnum.Teacher,
  RoleEnum.Controller,
  RoleEnum.Student,
];

export const ClaimEnum = {
  IsProduction: "IsProduction",
  IsLocal: "IsLocal",
  UserData: "http://schemas.microsoft.com/ws/2008/06/identity/claims/userdata",
  Right: "Right"
};


