



















import { Component, Vue } from "vue-property-decorator";
import { LessonItemModel, LessonService } from "@/services/service";

@Component({ components: {} })
export default class StudentCourse extends Vue {
  private lessons: LessonItemModel[] = [];
  async created() {
    const id = this.$route.params.id;
    this.lessons = await LessonService.getCourseLessons({
      courseId: parseInt(id),
    });
  }
  goToLesson(lesson) {
    this.$router.push({
      name: "StudentDesktopLesson",
      params: { id: lesson.Id },
    });
  }
}
