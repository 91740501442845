






































import { Component, Vue } from "vue-property-decorator";
import { Field } from "@/interfaces/Table";
import {
  AddEditStudentModel,
  AddEditUserModel,
  RightService,
  RoleRightModel,
  UserItemModel,
  UserService,
} from "@/services/service";
import AddStudent from "@/views/Users/AddStudent.vue";
import AddUser from "@/views/Users/AddUser.vue";
import { RoleList } from "@/constants/auth";

@Component({ components: { AddStudent, AddUser } })
export default class UserList extends Vue {
  private items: UserItemModel[] = [];
  private dialog = false;
  private studentDialog = false;
  private userDialog = false;
  private studentModel = {} as AddEditStudentModel;
  private userModel = {} as AddEditUserModel; 
  private allRoleRights = [] as Array<RoleRightModel>;

  private addStudent() {
    this.studentModel = {
      FirstName: "",
      Password: (Math.random() * 10000).toString().substring(0, 4),
      Sex: true,
    } as AddEditStudentModel;
    this.studentDialog = true;
  }

  private addUser() {
    this.userModel = { ΕmployeeDocuments: [], UserCustomRights: [] } as AddEditUserModel;
    this.userDialog = true;
  }

  private studentAdded(item) {
    this.items.push(item);
    this.studentDialog = false;
    this.userDialog = false;
  }

  private studentChanged(item) {
    const items = this.items.filter((i) => i.Id != item.Id);
    items.push(item);
    this.items = items;
    this.studentDialog = false;
    this.userDialog = false;
  }

  private studentCanceled() {
    this.studentDialog = false;
  }

  private userCanceled() {
    this.userDialog = false;
  }

  public async editUser(item: UserItemModel) {
    if (item.Role == "Student") {
      const model = await UserService.getByIdStudent({ id: item.Id });
      this.studentModel = model;
      this.studentDialog = true;
    } else {
      const model = await UserService.getByIdUser({ id: item.Id });
      this.userModel = model;
      this.userDialog = true;
    }
  }

  get getHeaders() {
    const headers: Array<Field> = [
      { text: "Фамилия", value: "LastName", sortable: false },
      { text: "Имя", value: "FirstName", sortable: false },
      { text: "Отчество", value: "MiddleName" },
      { text: "Роль", value: "Role" },
    ];
    return headers;
  }

  async created() {
    await this.getData();
  }

  private async getData() {
    this.items = await UserService.getUsers();   
    this.allRoleRights = await RightService.getAllRoleRights();
  }
  getRoleTitle(item: UserItemModel) {
    if (!item.Role) {
      return "";
    }
    const role = RoleList.find(
      (i) => i.authtext.toLowerCase() == item.Role?.toLowerCase()
    );
    return role?.text;
  }
}
