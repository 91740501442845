




























































import { LessonModel, TestModel } from "@/services/service";
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import {  
  TestPositionEnum,
  TestPositionList,
} from "@/enums/courseEnums";
import { saveToArray } from "@/helpers/arrayHelper";
@Component
export default class AddEditTest extends Vue {
  @Prop({ default: true }) model?: TestModel;
  @Prop() lessons?: LessonModel[];
  private dialog = true;
  private TestPositionList = TestPositionList;
  private TestPositionEnum = TestPositionEnum;
  private showLesson = false;

  private TestPositionChanged() {
    this.showLesson =
      this.model!.TestPosition === TestPositionEnum.BeforeLesson ||
      this.model!.TestPosition === TestPositionEnum.AfterLesson;
  }

  private getTitle(): string {
    return "Добавить тест";
  }

  private save() {
    this.$emit("saved", this.model);
  }

  private cancel() {
    this.$emit("canceled");
  }

  created() {
    this.TestPositionChanged();
  }

  @Watch("dialog")
  dialogChanged(val) {
    if (!val) {
      this.$emit("canceled");
    }
  }
}
