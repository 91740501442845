






































































import { Field } from "@/interfaces/Table";
import { EditCourseModel, LessonModel, CourseService, TestModel } from "@/services/service";
import { Component, Vue, Watch } from "vue-property-decorator";
import AddEditLesson from "./Lessons/AddEditLesson.vue";
import AddEditTest from "./Tests/AddEditTest.vue";
import { cloneDeep } from "lodash";
import { saveToArray, maxId } from "@/helpers/arrayHelper";

@Component({ components: { AddEditLesson, AddEditTest } })
export default class EditCourse extends Vue {
    private lessons: Array<LessonModel> = [];
    private model: EditCourseModel = { Lessons: [], Tests: [] };
    private showAddEditLesson = false;
    private addEditLesson: LessonModel | undefined = undefined;
    private showAddEditTest = false;
    private addEditTest: TestModel | undefined = undefined;

    private addLesson() {
        this.addEditLesson = new LessonModel();
        this.addEditLesson.InnerId = maxId(this.model.Lessons!);
        this.addEditLesson.Questions = [];
        this.showAddEditLesson = true;
    }

    private saveLesson(val: LessonModel) {
        saveToArray(this.model.Lessons!, val);
        this.showAddEditLesson = false;
    }

    private deleteLesson(val: LessonModel) {
        const index = this.model.Lessons?.indexOf(val);
        this.model.Lessons?.splice(index!, 1);
    }

    private addTest() {
        this.addEditTest = new TestModel();
        this.addEditTest.InnerId = maxId(this.model.Tests!);
        this.showAddEditTest = true;
    }

    private showTest(val: TestModel) {
        this.addEditTest = cloneDeep(val);
        this.showAddEditTest = true;
    }

    private saveTest(val: TestModel) {
        saveToArray(this.model.Tests!, val);
        this.showAddEditTest = false;
    }
    private deleteTest(val: TestModel) {
        const index = this.model.Tests?.indexOf(val);
        this.model.Tests?.splice(index!, 1);
    }

    private cancelShowTest() {
        this.showAddEditTest = false;
    }

    private showLesson(val: LessonModel) {
        this.addEditLesson = cloneDeep(val);
        this.showAddEditLesson = true;
    }

    private cancelShowLesson() {
        this.showAddEditLesson = false;
    }

    private async save() {
        const result = await CourseService.updateCourse({ body: this.model });
        this.$router.push({ name: "CourseList" });
    }

    private cancel() {
        this.$router.push({ name: "CourseList" });
    }

    get getHeaders() {
        const headers: Array<Field> = [
            { text: "", value: "Image", sortable: false, width: "50" },
            { text: "Название", value: "Title", sortable: false },
            { text: "", value: "actions", sortable: false, width: "100" },
        ];
        return headers;
    }

    async created() {
        const id = parseInt(this.$route.params.id);
        this.model = await CourseService.getById({ id: id });
    }
}
