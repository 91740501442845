




















































import { setFilePropertyToEntity } from "@/helpers/fileHelper";
import { Field } from "@/interfaces/Table";
import { AnswerModel } from "@/services/service";
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { QuestionTypeList } from "@/enums/courseEnums";
import { saveToArray } from "@/helpers/arrayHelper";
@Component
export default class AddEditAnswer extends Vue {
  @Prop({ default: true }) model?: AnswerModel;
  private dialog = true;
  private fileName = "";
  private currentFile: File | null = null;

  private getTitle(): string {
    return "Добавить ответ";
  }

  private save() {   
    this.$emit("saved", this.model);
  }

  private cancel() {
    this.$emit("canceled");
  }

  @Watch("dialog")
  dialogChanged(val) {
    if (!val) {
      this.$emit("canceled");
    }
  }

  private async selectFile(file: File) {
    this.fileName = file?.name;
    await setFilePropertyToEntity(file, this.model);
    this.currentFile = file;
  }
}
